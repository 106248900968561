<template>
    <div>
        <div class="title">Deine Angaben</div>
        <div class="headline-item-distance" />

        <span id="support-form" />
        <div uk-grid>
            <div
                v-for="(inputField, key) in textFields"
                :key="inputField.name"
                :class="inputField.width">
                <TextArea
                    v-if="inputField.type === 'textarea'"
                    v-model="inputField.value"
                    :autofocus="inputField.autofocus"
                    :field-key="key"
                    :icon="inputField.icon"
                    :label="inputField.label"
                    :name="inputField.name"
                    :required="inputField.required"
                    :rules="inputField.rules"
                    :type="inputField.type"
                    :validate="trigger"
                    @setValid="setValid" />
                <TextField
                    v-else
                    v-model="inputField.value"
                    :autocomplete="inputField.autocomplete"
                    :autofocus="inputField.autofocus"
                    :field-key="key"
                    :hasPasswordMeter="inputField.hasPasswordMeter"
                    :icon="inputField.icon"
                    :label="inputField.label"
                    :name="inputField.name"
                    :onEnter="inputField.onEnter"
                    :required="inputField.required"
                    :rules="inputField.rules"
                    :type="inputField.type"
                    :validate="trigger"
                    :is-optional="inputField.optional"
                    @setValid="setValid" />
            </div>
        </div>

        <div v-if="false" class="uk-grid" uk-grid>
            <div class="uk-width-1-1">
                <Checkbox
                    v-model="subscribe"
                    :value="subscribe"
                    label="Ich bin einverstanden, E-Mails zu den ausgewählten Optionen zu bekommen."
                    name="subscribe" />
            </div>
        </div>

        <div class="uk-margin-top" />
        Sobald du auf "<span class="bold">Jetzt abschicken & helfen!</span>"
        klickst, erhältst du eine Bestätigungsmail mit allen wichtigen Links und
        Informationen. Danke, dass du Teil von Cancel Cancer bist – gemeinsam
        machen wir den Unterschied!

        <div class="uk-margin-medium-top" />
        <div class="uk-text-center">
            <div
                :uk-tooltip="
                    !donate && !photos && !member
                        ? 'title:Bitte wähle mindestens eine Option aus.'
                        : 'title:'
                ">
                <Button
                    :is-disabled="!donate && !photos && !member"
                    text="Jetzt abschicken & helfen!"
                    is-large
                    is-min-width
                    :is-loading="loading"
                    has-action
                    :action="contact"
                    mode="success" />
            </div>
        </div>

        <div v-if="error" class="uk-margin-medium-bottom" />
        <ErrorMessage :error="error" />
    </div>
</template>

<script>
import axios from 'axios';
import Button from '@/components/Button';
import TextField from '@/components/TextField';
import TextArea from '@/components/TextArea';
import Checkbox from '@/components/Checkbox';
import ErrorMessage from '@/components/ErrorMessage.vue';
import UIkit from 'uikit';

export default {
    name: 'EventForm2',
    components: {
        ErrorMessage,
        Checkbox,
        TextArea,
        TextField,
        Button,
    },
    props: {
        photos: Boolean,
        member: Boolean,
        donate: Boolean,
    },
    data() {
        return {
            trigger: 0,
            textFields: [
                {
                    autofocus: false,

                    value: '',
                    valid: false,
                    name: 'name',
                    label: 'name',
                    rules: 'required',
                    icon: 'user',
                    width: 'uk-width-1-2@s uk-width-1-1',
                    required: true,
                    autocomplete: 'on',
                },

                {
                    value: '',
                    valid: false,

                    name: 'email',
                    label: 'emailAddress',
                    rules: 'required|email',
                    icon: 'mail',
                    width: 'uk-width-1-2@s uk-width-1-1',
                    required: true,
                    autocomplete: 'on',
                },
            ],

            salutation: '',
            salutationErr: false,
            withPartner: false,
            subscribe: false,

            error: null,
            loading: false,
            success: false,
        };
    },

    methods: {
        setValid(valid, fieldKey) {
            this.textFields[fieldKey].valid = valid;
        },
        contact() {
            if (!this.donate && !this.photos && !this.member) {
                return;
            }

            this.trigger++;

            for (let i = 0, j = this.textFields.length; i < j; i++) {
                if (!this.textFields[i].valid) {
                    UIkit.scroll('#support-form', {offset: 100}).scrollTo(
                        '#support-form'
                    );
                    return;
                }
            }

            this.loading = true;

            let http = axios.create();
            http.post(this.getBaseUrl + '/api/supports/berlinale2025', {
                name: this.textFields[0].value,
                email: this.textFields[1].value,

                member: this.member,
                donate: this.donate,
                photos: this.photos,
            })
                .then(() => {
                    this.error = null;
                    this.success = true;
                    this.loading = false;

                    this.$emit('success', true);
                })
                .catch((e) => {
                    this.error = e;
                    this.success = false;
                    this.loading = false;
                });
        },
    },
};
</script>

<style lang="scss" scoped>
.loading {
    height: 50px !important;
}

.feedback-thanks {
    font-size: 18px;
    height: 50px;
}

.contribution {
    font-weight: 500;
    font-size: 48px;
    color: #fff !important;
}

.uk-card-success {
    background-color: #0d5746 !important;
    box-shadow: 0 14px 25px rgba(0, 0, 0, 0.16);
}

.hint-2 {
    margin-top: 20px;
    font-size: 14px;
}

.unified-modifiers {
    width: 110px;
}

.sepa-title {
    font-weight: 500;
    margin-top: 5px;
}

.begleitung {
    width: 200px;
}

.title {
    font-size: 18px;
    font-weight: 500;
}

.bold {
    font-weight: 500;
}
</style>
