<template>
    <div class="site-width uk-animation-fade">
        <Seo
            :canonical="'/' + $t('support').toLowerCase()"
            :title="$t('support')" />

        <div class="menu-headline-distance" />
        <SiteHeadline
            is-main
            message="Werde Teil von Cancel Cancer!<br/><br/>Gemeinsam gegen Kinderkrebs – Einfach. Schnell. Wirkungsvoll." />
        <div class="headline-message-distance" />

        <span id="support-success" />
        <div v-if="success">
            <div
                class="uk-card uk-card-alternative uk-dark-card-hovered uk-padding site-width single">
                <div class="uk-margin-bottom uk-text-center">
                    <Icon icon="heart" :ratio="3" />
                </div>
                <FeatureItem
                    class="site-width single uk-text-center"
                    message="Danke, dass du Teil von Cancel Cancer bist – gemeinsam machen wir den Unterschied!<br/><br/>Folgende Links und Informationen sind auf dem Weg zu dir per E-Mail." />

                <div class="uk-margin-medium-top" />
                <div
                    class="uk-text-center uk-child-width-1-3@l uk-child-width-1-2@m uk-flex-center uk-child-width-1-1 uk-grid-match uk-grid-small"
                    uk-grid>
                    <div v-if="photos">
                        <div
                            class="uk-card uk-card-alternative uk-dark-card-hovered uk-padding site-width single uk-height-1-1">
                            <div class="title">Event Fotos</div>

                            <div class="uk-margin-top" />
                            <a
                                href="https://www.picdrop.com/cancelcancer/berlinale2025"
                                target="_blank">
                                <Button mode="primary" is-large>
                                    Zu den Fotos
                                </Button>
                            </a>
                        </div>
                    </div>

                    <div v-if="member">
                        <div
                            class="uk-card uk-card-alternative uk-dark-card-hovered uk-padding site-width single uk-height-1-1">
                            <div class="title">Mitgliedschaft</div>

                            <div class="uk-margin-top" />
                            <a
                                href="https://cancelcancer.de/mitgliedwerden"
                                target="_blank">
                                <Button is-large mode="primary">
                                    Mitglied werden
                                </Button>
                            </a>
                        </div>
                    </div>

                    <div v-if="donate">
                        <div
                            class="uk-card uk-card-alternative uk-dark-card-hovered uk-padding site-width single uk-height-1-1">
                            <div class="title">Spende</div>

                            <div class="uk-margin-top" />
                            <a
                                href="https://www.paypal.com/donate/?hosted_button_id=SZZVSE8W73FQG"
                                target="_blank">
                                <Button mode="primary" is-large>
                                    Zu PayPal
                                </Button>
                            </a>

                            <div class="uk-margin-top" />
                            <a
                                href="https://cancelcancer.de/de/spenden#ueberweisung"
                                target="_blank">
                                <Button is-large mode="primary">
                                    Banküberweisung
                                </Button>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div v-else>
            <FeatureItem
                class="site-width single uk-text-center uk-margin-medium-top"
                message="Schön, dass Du bei unserem Berlinale Event 2025 dabei bist! Wähle aus, wie Du uns unterstützen möchtest – wir kümmern uns um den Rest." />

            <div class="uk-margin-large-top" />
            <div class="info-message uk-text-center">Was möchtest Du tun?</div>

            <div class="uk-margin-medium-top" />
            <div
                class="uk-child-width-1-3@l uk-child-width-1-2@m uk-flex-center uk-child-width-1-1 uk-grid-match"
                uk-grid>
                <div>
                    <div
                        class="uk-card uk-card-alternative uk-dark-card-hovered uk-padding site-width single uk-height-1-1">
                        <div class="uk-grid-small uk-grid-match" uk-grid>
                            <div class="uk-width-auto">
                                <SwitchButton v-model="photos" id="photos" />
                            </div>
                            <div class="uk-width-expand">
                                <div class="title uk-flex-middle uk-flex">
                                    <Icon icon="camera" :ratio="1.3" />
                                    <span class="title-text"
                                        >Event-Fotos erhalten</span
                                    >
                                </div>
                            </div>
                            <div class="uk-width-1-1">
                                <div class="description">
                                    Erhalte exklusive Bilder von heute direkt
                                    per Mail, sobald sie verfügbar sind!
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div>
                    <div
                        class="uk-card uk-card-alternative uk-dark-card-hovered uk-padding site-width single uk-height-1-1">
                        <div class="uk-grid-small uk-grid-match" uk-grid>
                            <div class="uk-width-auto">
                                <SwitchButton v-model="member" id="member" />
                            </div>
                            <div class="uk-width-expand">
                                <div class="title uk-flex-middle uk-flex">
                                    <Icon icon="heart" :ratio="1.3" />
                                    <span class="title-text"
                                        >Mitglied werden</span
                                    >
                                </div>
                            </div>
                            <div class="uk-width-1-1">
                                <div class="description">
                                    Werde Teil unserer Community und hilf uns,
                                    Kinderkrebs den Kampf anzusagen. Du bekommst
                                    alle Infos per Mail – einfach und
                                    unverbindlich.
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div>
                    <div
                        class="uk-card uk-card-alternative uk-dark-card-hovered uk-padding site-width single uk-height-1-1">
                        <div class="uk-grid-small uk-grid-match" uk-grid>
                            <div class="uk-width-auto">
                                <SwitchButton v-model="donate" id="donate" />
                            </div>
                            <div class="uk-width-expand">
                                <div class="title uk-flex-middle uk-flex">
                                    <Icon
                                        v-if="false"
                                        icon="credit-card"
                                        :ratio="1.3" />
                                    <img
                                        class="paypal-logo"
                                        :src="
                                            require('../../assets/' +
                                                getMode +
                                                '/images/paypal.svg')
                                        " />
                                    <span class="title-text">Spende</span>
                                </div>
                            </div>
                            <div class="uk-width-1-1">
                                <div class="description">
                                    <div
                                        v-if="false"
                                        class="uk-child-width-auto uk-grid-small uk-grid-match uk-flex-middle"
                                        uk-grid>
                                        <div class="">
                                            <img
                                                class="donate-logo"
                                                :src="
                                                    require('../../assets/' +
                                                        getMode +
                                                        '/images/paypal.svg')
                                                " />
                                        </div>

                                        <div class="">
                                            <img
                                                class="donate-logo"
                                                :src="
                                                    require('../../assets/' +
                                                        getMode +
                                                        '/images/sepa.png')
                                                " />
                                        </div>
                                    </div>
                                    Jede Spende hilft! Du bekommst einen Link zu
                                    unserer Spendenplattform und kannst sofort
                                    etwas bewirken.
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="uk-margin-medium-top" />
            <div
                class="uk-card uk-card-alternative uk-dark-card-hovered uk-padding site-width single">
                <EventForm2
                    @success="setSuccess"
                    :donate="donate"
                    :member="member"
                    :photos="photos" />
            </div>
        </div>
        <div class="last-button-distance" />
    </div>
</template>

<script>
import SiteHeadline from '../../components/SiteHeadline.vue';
import Seo from '../../components/Seo.vue';
import FeatureItem from '@/components/FeatureItem.vue';
import SwitchButton from '../../components/SwitchButton.vue';
import Icon from '../../components/Icon.vue';
import EventForm2 from './EventForm2.vue';
import UIkit from 'uikit';
import Button from '../../components/Button.vue';

export default {
    name: 'Berlinale2025Event',
    components: {
        Button,
        EventForm2,
        Icon,
        SwitchButton,
        FeatureItem,
        Seo,
        SiteHeadline,
    },
    data() {
        return {
            donate: false,
            member: false,
            photos: false,

            success: false,
        };
    },
    created() {
        if (this.getMode !== 'cancelcancer') {
            this.routeTo('');
        }
    },
    methods: {
        setSuccess() {
            this.success = true;

            UIkit.scroll('#support-success', {offset: 120}).scrollTo(
                '#support-success'
            );
        },
    },
};
</script>

<style lang="scss" scoped>
.image {
    border-radius: $border-radius;
}

.donate-logo {
    margin: auto;
    max-width: 80px;
}

.paypal-logo {
    max-width: 80px;
}

.video-title {
    font-size: 22px;
    margin-top: 20px;
    font-weight: 500;
    margin-bottom: 10px;
}

.info-message {
    font-size: 24px;
    line-height: 1.16667;
    font-weight: 500;
    letter-spacing: 0.009em;
    color: #ddd;
}

.description {
    margin-top: 5px;
}

.title {
    font-size: 18px;
    font-weight: 500;
}

.title-text {
    margin-left: 10px;
}
</style>
