<template>
    <div class="site-width uk-animation-fade">
        <Seo
            :canonical="'/' + $t('stories').toLowerCase()"
            :title="$t('stories')" />

        <div class="menu-headline-distance" />
        <SiteHeadline
            is-main
            message="Geschichten, die bewegen.<br/>Gemeinsam für eine Kindheit ohne Krebs." />
        <div class="headline-message-distance" />

        <FeatureItem
            class="site-width single uk-text-center uk-margin-medium-top"
            message="Ein Ziel, viele Gesichter: Mit unseren Videos geben wir denjenigen eine Bühne, die ihre Geschichte mit uns teilen möchten. Cancel Cancer setzt sich ein für die Krebsforschung, Angstprävention und Zuversicht in schwierigen Zeiten." />

        <div class="section-distance" />
        <div
            class="uk-child-width-1-4@xl uk-child-width-1-2@m uk-child-width-1-1"
            uk-grid>
            <div v-for="video in videos" :key="video.id">
                <div
                    class="uk-height-medium uk-flex uk-flex-center uk-flex-middle uk-background-cover"
                    :data-src="
                        require('../../assets/' +
                            getMode +
                            '/images/' +
                            video.image)
                    "
                    uk-img>
                    <a href="#">
                        <Button
                            text="Ansehen"
                            is-large
                            is-min-width
                            mode="default"
                            :uk-toggle="'target:#modal-' + video.id" />
                    </a>
                </div>

                <div class="video-title">
                    {{ video.title }}
                </div>

                <div class="video-message">
                    {{ video.message }}
                </div>

                <VideoModal
                    :modal-id="'modal-' + video.id"
                    :video-src="video.source" />
            </div>
        </div>

        <div class="last-button-distance" />
    </div>
</template>

<script>
import SiteHeadline from '../../components/SiteHeadline.vue';
import Seo from '../../components/Seo.vue';
import FeatureItem from '@/components/FeatureItem.vue';
import VideoModal from '../../components/VideoModal.vue';
import Button from '../../components/Button.vue';

export default {
    name: 'Stories',
    components: {
        Button,

        VideoModal,
        FeatureItem,
        Seo,
        SiteHeadline,
    },
    data() {
        return {
            videos: [
                {
                    id: 'ufaxcancelcancer',
                    source: 'https://vimeo.com/1054943374/b7a312cc6b',
                    image: 'UFA x Cancel Cancer.png',
                    title: 'UFA x Cancel Cancer',
                    message:
                        'Hand in Hand für das gleiche Ziel: Gemeinsam mit der UFA fassten wir 2021 den Entschluss, die Welt ein kleines bisschen besser zu machen. Elf großartige Botschafter:innen geben unserem Vorhaben eine Stimme und erklären, worauf es bei Cancel Cancer ankommt: Hoffnung. Mit vereinten Kräften können wir etwas bewegen - für eine Kindheit ohne Krebs.',
                },
                {
                    id: 'milas-briefe',
                    source: 'https://vimeo.com/1054943267/55ad024fd4',
                    image: 'Milas Briefe.png',
                    title: 'Milas Briefe',
                    message:
                        'Kindsein bedeutet unbeschwert und voller Hoffnung in die Zukunft blicken zu können. Aber was machst Du, wenn Du nicht so viel Zeit hast wie andere Kinder? Der 13-jährige Milas hatte darauf eine Antwort: Erlebe so viele Abenteuer wie es nur geht und schreibe Deine eigenen Geschichten. Das hier ist seine. Milas leidete am Ewing Sarkom, einem bösartigen Krebstumor, der die Knochen befällt. Er befindet sich im Endstadium und hat nicht mehr viel Zeit. Er wird bald in sein Baumhaus gehen, aber er hat noch eine Botschaft für uns alle.',
                },
                {
                    id: 'experten-stimme',
                    source: 'https://vimeo.com/1056393489/cf4b587e0f',
                    image: 'Die Stimme der Experten.png',
                    title: 'Die Stimme der Experten',
                    message:
                        'Molekularbiologin Dr. Hanna Heikenwälder ist Wissenschaftlerin im Bereich der Krebsforschung und veranschaulicht, warum besonders Krebserkrankungen bei Kindern ein frühzeitiges Eingreifen erfordern. Dabei schildert sie, welches Potenzial aktuelle Forschungen besitzen und wie Cancel Cancer dabei unterstützt, dieses Potenzial auszuschöpfen.',
                },
                {
                    id: 'leos-erfolgsgeschichte',
                    source: 'https://vimeo.com/1056398132/920fe87ca1',
                    image: 'Die Erfolgsgeschichte von Leo.png',
                    title: 'Die Erfolgsgeschichte von Leo',
                    message:
                        'Leo war erst fünf Jahre alt, als er die Diagnose Krebs erhielt. Doch er hat es geschafft: Er ist heute neun Jahre alt - und gesund. Sein Vater Youri Ziffzer von Adler Mannheim spricht über den Kampf gegen den Krebs und ist überzeugt: Kämpfen geht nicht allein, sondern nur im Team. Mit Cancel Cancer steht eine ganze Mannschaft bereit, um den Krebs in seine Schranken zu weisen.',
                },
            ],
        };
    },
    created() {
        if (this.getMode !== 'cancelcancer') {
            this.routeTo('');
        }
    },
};
</script>

<style lang="scss" scoped>
.image {
    border-radius: $border-radius;
}

.video-title {
    font-size: 22px;
    margin-top: 20px;
    font-weight: 500;
    margin-bottom: 10px;
}
</style>
