<template>
    <div id="app">

        <seo-title :content="$t('mainTitle')"/>
        <seo-description :content="$t('seoDescription')"/>
        <seo-author content="Aykut Yalcin (a.yalcin@camcore.de)"/>
        <seo-keywords :content="getConfig.seoKeywords"/>

        <div class="navbar-struct"
             uk-sticky="sel-target: .navbar-container; cls-active: uk-navbar-sticky navbar-sticky-struct; cls-inactive: uk-navbar-transparent;">

            <nav>
                <div
                    class="uk-container navbar-elements uk-padding-remove-left-desktop uk-padding-remove-right-desktop">

                    <div   class="uk-hidden@l" uk-navbar>
                        <div class="uk-navbar-left" v-if="$route.name !== 'inentwicklung' && getConfig.menu && getConfig.menu.length>0">
                            <ul class="uk-navbar-nav">
                                <li>
                                    <LinkObject v-if="getMode==='cancelcancer'" :to="$t('membership.message').toLowerCase()"
                                                :uk-tooltip="'title:'+$t('membership.message')">
                                        <span uk-icon="icon: heart; ratio: 1.4"/>
                                    </LinkObject>


                                    <LinkObject v-else :to="$t('contact').toLowerCase()"
                                                :uk-tooltip="'title:'+$t('freeOffer')">
                                        <span class="uk-margin-small-right" uk-icon="icon: mail; ratio: 1.4"/>
                                        <span uk-icon="icon: receiver; ratio: 1.4"/>
                                    </LinkObject>
                                </li>
                            </ul>
                        </div>
                        <div class="uk-navbar-center">
                            <router-link v-if="$route.name !== 'main'" :to="'/'+$i18n.locale+'/'"
                                         class="uk-navbar-item uk-logo">
                                <img
                                    v-if="$route.name === 'inentwicklung'"
                                    alt="Logo" class="logo" :src="require('./assets/'+getMode+'/vegankilling/camcore.png')"/>
                                <img v-else alt="Logo" class="logo" :src="require('./assets/'+getMode+'/logo.'+(getMode ==='camcore' ? 'png' : 'svg'))"/>

                                <img
                                    v-if="$route.name === 'inentwicklung'"
                                    alt="Logo" class="logo uk-margin-small-left" :src="require('./assets/'+getMode+'/vegankilling/dropkick.png')"/>

                            </router-link>
                            <a v-else class="uk-navbar-item uk-logo">
                                <img alt="Logo" class="logo" href="#app" :src="require('./assets/'+getMode+'/logo.'+(getMode ==='camcore' ? 'png' : 'svg'))"
                                     uk-scroll/>
                            </a>


                        </div>
                        <div class="uk-navbar-right" v-if="getConfig.menu && getConfig.menu.length>0">
                            <ul class="uk-navbar-nav">
                                <li>
                                    <a uk-icon="icon: menu; ratio: 1.4" uk-toggle="target: #nav-mobile"/>
                                </li>
                            </ul>
                        </div>
                    </div>

                    <div class="uk-visible@l" uk-navbar>
                        <div :class="'uk-navbar-left '+(getMode==='?'? 'uk-invisible':'')">
                            <router-link v-if="$route.name !== 'main'" :to="'/'+$i18n.locale+'/'"
                                         class="uk-navbar-item uk-logo uk-padding-remove-left-desktop">


                                <img
                                    v-if="$route.name === 'inentwicklung'"
                                    alt="Logo" class="logo" :src="require('./assets/'+getMode+'/vegankilling/camcore.png')"/>
                                <img v-else alt="Logo" class="logo" :src="require('./assets/'+getMode+'/logo.'+(getMode ==='camcore' ? 'png' : 'svg'))"/>

                                <img
                                    v-if="$route.name === 'inentwicklung'"
                                    alt="Logo" class="logo uk-margin-small-left" :src="require('./assets/'+getMode+'/vegankilling/dropkick.png')"/>



                            </router-link>
                            <a v-else
                               class="uk-navbar-item uk-logo uk-padding-remove-left-desktop">
                                <img alt="Logo" class="logo" href="#app" :src="require('./assets/'+getMode+'/logo.'+(getMode ==='camcore' ? 'png' : 'svg'))"
                                     uk-scroll/>
                            </a>
                        </div>

                        <div v-if="getMode==='?'" class="uk-navbar-center">
                            <router-link v-if="$route.name !== 'main'" :to="'/'+$i18n.locale+'/'"
                                         class="uk-navbar-item uk-logo">
                                <img alt="Logo" class="logo" :src="require('./assets/'+getMode+'/logo.'+(getMode ==='camcore' ? 'png' : 'svg'))"/>
                            </router-link>
                            <a v-else class="uk-navbar-item uk-logo">
                                <img alt="Logo" class="logo" href="#app" :src="require('./assets/'+getMode+'/logo.'+(getMode ==='camcore' ? 'png' : 'svg'))"
                                     uk-scroll/>
                            </a>
                        </div>


                        <div class="uk-navbar-right">
                            <ul class="uk-navbar-nav">
                                <li v-for="link in getConfig.menu" :key="link">
                                    <LinkObject :to="(link === 'aboutUs'?  $t(link+'Link') : $t(link))">

                                        <Button v-if="link === 'contact' && getMode!=='cancelcancer' && getMode !== 'camcore'"
                                                :uk-tooltip="'title:'+$t('freeOffer')"
                                                mode="success">
                                            {{ $t(link) }}
                                        </Button>
                                        <Button v-else-if="link === 'membership.message' && getMode ==='cancelcancer'"
                                                mode="success">
                                            {{ $t(link) }}
                                        </Button>
                                        <Button v-else-if="link === 'donate' && getMode ==='cancelcancer'"
                                                mode="primary">
                                            {{ $t(link) }}
                                        </Button>
                                        <span v-else>
                                            {{ $t(link) }}
                                        </span>

                                    </LinkObject>
                                </li>
                                <li v-if="getMode === 'camcore'">
                                    <a class="uk-padding-remove-right-desktop language "
                                       uk-toggle="target: #modal-language">
                                        <span class="uk-text-uppercase lang-struct">
                                            {{ $i18n.locale }} <span class="language-dist"
                                                                     uk-icon="icon: more-vertical; ratio: 0.8"></span>
                                        </span>
                                    </a>
                                </li>
                            </ul>
                        </div>
                    </div>

                </div>
            </nav>
        </div>


        <div id="nav-mobile" uk-offcanvas="overlay: true; flip: true;">
            <div class="uk-offcanvas-bar">
                <button class="uk-modal-close-default" type="button" uk-close uk-toggle="target: #nav-mobile"></button>

                <ul class="uk-nav uk-nav-default">


                    <span v-for="(link,key) in getConfig.menu" :key="link">
                        <li uk-toggle="target: #nav-mobile">
                            <LinkObject :to="(link === 'aboutUs'?  $t(link+'Link') : $t(link))" class="menu-item">

                                <Button v-if="link ==='contact' && getMode!=='cancelcancer' && getMode !=='camcore'"
                                        :uk-tooltip="'title:'+$t('freeOffer')"
                                        mode="success"
                                        uk-toggle="target: #nav-mobile">
                                    {{ $t(link) }}
                                </Button>
                                      <Button v-else-if="link === 'membership.message' && getMode ==='cancelcancer'"
                                              mode="success"
                                              uk-toggle="target: #nav-mobile">
                                    {{ $t(link) }}
                                </Button>
                                <Button v-else-if="link === 'donate' && getMode ==='cancelcancer'"
                                        mode="primary"
                                        uk-toggle="target: #nav-mobile">
                                    {{ $t(link) }}
                                </Button>
                                <span v-else>
                                         {{ $t(link) }}
                                </span>

                            </LinkObject>
                        </li>
                        <li class="uk-nav-divider" v-if="key !== getConfig.menu.length -1 || getMode ==='camcore'"/>
                    </span>

                    <li v-if="getMode === 'camcore'" class="uk-text-uppercase">
                        <a class="menu-item" href="" uk-toggle="target: #modal-language">
                            <span class="uk-margin-small-right">
                                <img :alt="$i18n.locale + ' Icon'"
                                     :src="require('./assets/images/'+$i18n.locale+'.svg')"
                                     class="country-flag reset-line-height" uk-img>
                            </span>
                            {{ $i18n.locale }}
                        </a>
                    </li>

                </ul>

            </div>
        </div>


        <div id="modal-language" uk-modal>
            <div class="uk-modal-dialog uk-modal-body language-modal uk-light">
                <button class="uk-visible@m uk-modal-close-outside" type="button" uk-close></button>
                <button class="uk-hidden@m uk-modal-close-default" type="button" uk-close></button>

                <h2 class="uk-modal-title uk-margin-bottom uk-margin-remove-top">
                    {{ $t('selectYourLanguage') }}</h2>


                <table class="uk-table uk-table-hover uk-table-divider uk-table-middle uk-margin-top">
                    <tbody v-for="language in this.languages"
                           :key="language">
                    <tr class="language-element uk-modal-close" v-on:click="switchLanguage(language)">
                        <td class="uk-width-mini">
                            <img :alt="language+' Icon'" :src="require('./assets/images/'+language+'.svg')"
                                 class="country-flag" uk-img>
                        </td>
                        <td><span class="uk-margin-small-left">{{ $t(language, language) }}</span></td>
                    </tr>
                    </tbody>
                </table>


            </div>
        </div>

        <router-view/>

        <Footer :links="getConfig.menu"/>

        <CookieControl v-if="!$cookie.get('opt-out')"/>

    </div>
</template>


<script>

import Footer from "./components/Footer";
import Vue from "vue";
import axios from 'axios';
import LinkObject from "./components/LinkObject";
import CookieControl from "./components/CookieControl";
import UIkit from "uikit";
import Button from "@/components/Button.vue";
import {i18n} from "@/main";
// import {useFavicon} from '@vueuse/core'


Vue.mixin({
    data() {
        return {
            camcoreConfig: {
                name: "Camcore",
                url: "camcore.de",
                menu: ['ourIdea', 'inDevelopment', 'references',  'aboutUs', 'news','contact'],
                seoKeywords: "camcore, berlin, deutschland, espelkamp, germany, kurzfilm, kurzfilme, kurz, film, filme, werbung, social, media, marketing, commercial, luftaufnahmen, luft, aufnahmen, air, images, airimages, fiction, fiktion, editing, movie, video, kino, kinofilm, phantom, schmerz, phantomschmerz",
                seoTitle: "Camcore.de",
                seoImage: "socialmedia.png",
                clarityKey: "5cmpj4otqq",

                buttons: [],
                showreel: "https://vimeo.com/762377005",
                awards: [
                    "cannes.png",
                    "deutscherkamerapreis.png",
                    "jupiter.png",
                    "lafa.png",
                    "selb.png",
                    "shokingshorts.png",
                ],

                email: "info@camcore.de",
                telDE: "030 58583205",
                telEN: "+49 30 58583205",
                locations: [
                    {
                        logo: "berlin.svg",
                        name: "Berlin",
                        contact: "Camcore UG (haftungsbeschränkt)",
                        street: "Sprengelstraße 39",
                        zip: "13353",
                        city: "Berlin",
                    },
                ],

                socials: [
                    {name: 'instagram', link: 'https://www.instagram.com/camcore.de/'},
                    {name: 'youtube', link: 'https://www.youtube.com/user/CamcoreFilmteam'},
                    // {name: 'twitter', link: 'https://twitter.com/CamcorePictures'},
                    // {name: 'facebook', link: 'https://www.facebook.com/camcore/'},
                    {name: 'linkedin', link: 'https://linkedin.com/company/camcore'},
                ],


                imagefilmSteps: [
                    {
                        title: "reliable",
                        message: "reliable.message",
                        icon: "heart",
                        translate: true
                    },

                    {
                        title: "visionary",
                        message: "visionary.message",
                        icon: "star",
                        translate: true
                    },
                    {
                        title: "trustful",
                        message: "trustful.message",
                        icon: "check",
                        translate: true
                    },
                ],

                bts: "https://vimeo.com/928305056",

                aboutUsSteps: [
                    /*
                   {
                       "title": "history",
                       "icon": "history",
                       "message": "historyMessage",
                       translate: true
                   },
                   {
                       "title": "vision",
                       "icon": "star",
                       "message": "visionMessage",
                       translate: true
                   },
                     */
                ],

                commercialSteps: [
                    {
                        title: "reliable",
                        message: "reliable.message",
                        icon: "heart",
                        translate: true
                    },

                    {
                        title: "visionary",
                        message: "visionary.message",
                        icon: "star",
                        translate: true
                    },
                    {
                        title: "trustful",
                        message: "trustful.message",
                        icon: "check",
                        translate: true
                    },
                ],

                team: [
                    {
                        titleDE: "Gründer & Geschäfts&shy;führender Produzent",
                        titleEN: "",
                        video: "dlittau.mp4",
                        name: "Daniel Littau",
                        email: "d.littau@",
                    },
                    {
                        titleDE: "Gründer & Geschäfts&shy;führender Produzent",
                        titleEN: "",
                        video: "aolenberg.mp4",
                        name: "Andreas Olenberg",
                        email: "a.olenberg@",
                    },
                    {
                        titleDE: "Geschäfts&shy;führender Produzent",
                        titleEN: "",
                        video: "mmohnke.mp4",
                        name: "Manuel Mohnke",
                        email: "m.mohnke@",
                    },
                    {
                        titleDE: "Software Entwickler",
                        titleEN: "Software Developer",
                        video: "ayalcin.mp4",
                        name: "Aykut Yalcin",
                        email: "a.yalcin@",
                    },
                    {
                        titleDE: "Producer & Camera Operator",
                        titleEN: "",
                        video: "bleisten.mp4",
                        name: "Björn Leisten",
                        email: "b.leisten@",
                    },
                ],

                subteam: [
                    {
                        video: "lolenberg.mp4",
                        name: "Linda Olenberg",
                        titleDE: "Produktion",
                        titleEN: "Production",
                    },
                    {
                        video: "nretzlaff.mp4",
                        name: "Nicolai Retzlaff",
                        titleDE: "Musik",
                        titleEN: "Composer",
                    },
                    {
                        video: "skeilbach.mp4",
                        name: "Saskia Keilbach",
                        titleDE: "Social Media & Produktion",
                        titleEN: "Social Media & Production",
                    },
                    {
                        video: "sdukkardt.mp4",
                        name: "Sergej Dukkardt",
                        titleDE: "Kamera",
                        titleEN: "Camera Operator",
                    },
                    {
                        video: "astarczyk.mp4",
                        name: "Angie Starczyk",
                        titleDE: "Production",
                        titleEN: "",
                    },
                    {
                        video: "jctvrtnicek.mp4",
                        name: "Jan Ctvrtnicek",
                        titleDE: "Kamera",
                        titleEN: "Camera Operator",
                    },
                    {
                        video: "liolenberg.mp4",
                        name: "Lilli Olenberg",
                        titleDE: "Maske",
                        titleEN: "Make Up",
                    },
                    {
                        video: "efrantzen.mp4",
                        name: "Eric Frantzen",
                        titleDE: "Kamera & Editing",
                        titleEN: "Camera Operator & Editor",
                    },
                    {
                        video: "bniemeier.mp4",
                        name: "Bastian Niemeier",
                        titleDE: "Kamera",
                        titleEN: "Camera Operator",
                    },
                    {
                        video: "jnickel.mp4",
                        name: "Jona Nickel",
                        titleDE: "Kamera-Assistenz",
                        titleEN: "Assistant Camera",
                    },
                    {
                        video: "dmanns.mp4",
                        name: "Daniel Manns",
                        titleDE: "Stoff&shy;entwicklung & Editing",
                        titleEN: "Content Development & Editor",
                    },
                    {
                        video: "bdevletli.mp4",
                        name: "Baris Devletli",
                        titleDE: "Rechts&shy;abteilung",
                        titleEN: "Legal Counsel",
                    },
                ],

                features: [
                ],

                sitenotice: [
                    {
                        headline: "statementLaw",
                        message: "Camcore UG (haftungsbeschränkt)<br/>Sprengelstraße 39<br/>13353 Berlin<br/>Deutschland",
                        translateMessage: false
                    },
                    {
                        headline: "ceo",
                        message: "Daniel Littau<br/>Andreas Olenberg",
                        translateMessage: false
                    },
                    {
                        headline: "cto",
                        message: "Aykut Yalcin",
                        translateMessage: false
                    },
                    {
                        headline: "content",
                        message: "Daniel Littau<br/>Andreas Olenberg<br/>Aykut Yalcin",
                        translateMessage: false
                    },
                    {
                        headline: "designAndDevelopment",
                        message: "Aykut Yalcin",
                        translateMessage: false
                    },
                    {
                        headline: "englishTranslation",
                        message: "Aykut Yalcin",
                        translateMessage: false
                    },
                    {
                        headline: "contact",
                        message: "030 58583205<br/>info@camcore.de",
                        translateMessage: false
                    },
                    {
                        headline: "registryCourt",
                        message: "Amtsgericht Berlin",
                        translateMessage: false
                    },
                    {
                        headline: "commercialRegisterNumber",
                        message: "225482 B",
                        translateMessage: false
                    },
                    {
                        headline: "salesTax",
                        message: "DE311815562<br/>Finanzamt Wedding",
                        translateMessage: false
                    },
                    {
                        headline: "liabilityContent",
                        message: "liabilityContentMessage",
                        translateMessage: true
                    },
                    {
                        headline: "liabilityLinks",
                        message: "liabilityLinksMessage",
                        translateMessage: true
                    },
                    {
                        headline: "copyright",
                        message: "copyrightMessage",
                        translateMessage: true
                    },
                    {
                        headline: "dataPrivacy",
                        message: "dataPrivacyMessage",
                        translateMessage: true
                    },
                    {
                        headline: "cookies",
                        message: "cookiesMessage",
                        translateMessage: true
                    },
                    {
                        headline: "serverLogFiles",
                        message: "serverLogFilesMessage",
                        translateMessage: true
                    },
                    {
                        headline: "googleAnalytics",
                        message: "googleAnalyticsMessage",
                        translateMessage: true
                    },
                    {
                        headline: "sslEncryption",
                        message: "sslEncryptionMessage",
                        translateMessage: true
                    },
                    {
                        headline: "legalCaveatCommercialMails",
                        message: "legalCaveatCommercialMailsMessage",
                        translateMessage: true
                    },
                ],

            },

            filmpiratenConfig: {
                name: "Filmpiraten",
                url: "diefilmpiraten.de",
                menu: ['commercial', 'image', 'references', 'contact', 'aboutUs', 'news'],
                seoKeywords: "diefilmpiraten, filmpiraten, film, piraten, nrw, bielefeld, deutschland, germany, kurzfilm, kurzfilme, kurz, film, filme, werbung, image, imagefilm, social, media, marketing, commercial, luftaufnahmen, luft, aufnahmen, air, images, airimages, editing, movie, video",
                seoTitle: "DieFilmpiraten.de",
                seoImage: "socialmediafp.png",
                clarityKey: "i95fa751bw",

                buttons: ['commercial', 'image'],
                showreel: "https://vimeo.com/392448473",
                awards: [
                    "cannes.png",
                    "deutscherkamerapreis.png",
                    "jupiter.png",
                    "lafa.png",
                    "selb.png",
                    "shokingshorts.png",
                ],

                email: "info@diefilmpiraten.de",
                telDE: "0521 94560626",
                telEN: "+49 521 94560626",
                locations: [
                    {
                        logo: "bielefeld.svg",
                        name: "Bielefeld",
                        contact: "Die Filmpiraten GbR",
                        street: "Allensteiner Str. 1",
                        zip: "33605",
                        city: "Bielefeld",
                    },
                ],

                socials: [
                    {name: 'instagram', link: 'https://www.instagram.com/diefilmpiraten/'},
                    // {name: 'youtube', link: 'https://www.youtube.com/@diefilmpiraten2376'},
                    {name: 'vimeo', link: 'https://vimeo.com/diefilmpiraten'},
                    // {name: 'twitter', link: 'https://twitter.com/CamcorePictures'},
                    {name: 'facebook', link: 'https://www.facebook.com/diefilmpiraten/'},
                    // {name: 'linkedin', link: 'https://linkedin.com/company/camcore'},
                ],

                imagefilmSteps: [
                    {
                        title: "Kundengewinnung",
                        message: "Setzt die Segel und auf zu neuen Ufern. Mit Deinem Imagefilm findest Du Partnerschaften mit Tiefgang.",
                    },
                    {
                        title: "Branding",
                        message: "Werde zur Galionsfigur Deiner Branche. Egal ob bewundert oder gefürchtet. Mit Deinem Imagefilm präsentierst Du dich auf allen sieben Weltmeeren.",
                    },
                    {
                        title: "Recruiting",
                        message: "Von Matrosen bis zum Steuermann - mit Deinem neuen Recruitingfilm findest Du immer das passende Crewmitglied.",
                    },
                ],

                bts: "https://vimeo.com/928306075",

                aboutUsSteps: [
                    {
                        title: "reliable",
                        message: "reliable.message",
                        translate: true,
                    },
                    {
                        "title": "Anspruchsvoll",
                        "message": "Jedes einzelne Mitglied unserer Crew gibt alles, um den hoch angesetzten Ansprüchen eines Filmpiraten gerecht zu werden. In Summe entsteht ein Produkt, das in allen Aspekten von Vorproduktion über Drehbuch, Kamera, und Schnitt bis hin zur Musik wie eine Perle glänzt.",
                    },
                    {
                        title: "trustful",
                        message: "trustful.message",
                        translate: true,
                    },
                ],

                commercialSteps: [
                    {
                        title: "Kino",
                        message: "Hier werden auch kleine Geschichten groß erzählt und große Geschichten zum Blockbuster. Wir bringen Deinen Werbespot auf die Leinwand und machen ihn zum Hauptfilm.",
                    },

                    {
                        title: "TV",
                        message: "Jedes Format hat seine besonderen Herausforderungen. Wir machen Deinen Werbespots fit fürs Fernsehen und nehmen Kurs auf die Wohnzimmer Deiner zukünftigen Kunden.",
                    },
                    {
                        title: "Social Media",
                        message: "Weitergescrollt und schon vergessen? Nicht mit uns! Wir sorgen dafür, dass Dein Werbespot Anker wirft und lange im Gedächtnis bleibt.",
                    },
                ],

                team: [
                    {
                        titleDE: "Gründer & Geschäfts&shy;führender Produzent",
                        video: "mmohnke.mp4",
                        name: "Manuel Mohnke",
                        email: "m.mohnke@",
                    },
                    {
                        titleDE: "Gründer & Geschäfts&shy;führender Produzent",
                        video: "aolenberg.mp4",
                        name: "Andreas Olenberg",
                        email: "a.olenberg@",
                    },
                    {
                        titleDE: "Geschäfts&shy;führender Produzent",
                        video: "dlittau.mp4",
                        name: "Daniel Littau",
                        email: "d.littau@",
                    },
                    {
                        titleDE: "Produzent & Kameramann",
                        video: "bleisten.mp4",
                        name: "Björn Leisten",
                        email: "b.leisten@",
                    },
                    {
                        titleDE: "Software Entwickler",
                        video: "ayalcin.mp4",
                        name: "Aykut Yalcin",
                        email: "a.yalcin@",
                    },
                ],

                features: [],

                subteam: [
                    {
                        video: "astarczyk.mp4",
                        name: "Angie Starczyk",
                        titleDE: "Produktion",
                    },
                    {
                        video: "lolenberg.mp4",
                        name: "Linda Olenberg",
                        titleDE: "Produktion",
                    },
                    {
                        video: "skeilbach.mp4",
                        name: "Saskia Keilbach",
                        titleDE: "Social Media & Produktion",
                    },
                    {
                        video: "dmanns.mp4",
                        name: "Daniel Manns",
                        titleDE: "Regie & Stoff&shy;entwicklung",
                    },
                    {
                        video: "nretzlaff.mp4",
                        name: "Nicolai Retzlaff",
                        titleDE: "Musik",
                    },
                    {
                        video: "bniemeier.mp4",
                        name: "Bastian Niemeier",
                        titleDE: "Kamera",
                    },
                    {
                        video: "efrantzen.mp4",
                        name: "Eric Frantzen",
                        titleDE: "Kamera & Schnitt",
                    },
                    {
                        video: "jctvrtnicek.mp4",
                        name: "Jan Ctvrtnicek",
                        titleDE: "Kamera",
                    },
                    {
                        video: "jnickel.mp4",
                        name: "Jona Nickel",
                        titleDE: "Kamera",
                    },
                    {
                        video: "sdukkardt.mp4",
                        name: "Sergej Dukkardt",
                        titleDE: "Kamera",
                    },
                    {
                        video: "liolenberg.mp4",
                        name: "Lilli Olenberg",
                        titleDE: "Maske",
                    },
                ],

                sitenotice: [
                    {
                        headline: "statementLaw",
                        message: "Die Filmpiraten GbR<br/>Allensteiner Str. 1<br/>33605 Bielefeld<br/>Deutschland",
                        translateMessage: false
                    },
                    {
                        headline: "ceo",
                        message: "Andreas Olenberg<br/>Manuel Mohnke",
                        translateMessage: false
                    },
                    {
                        headline: "content",
                        message: "Daniel Littau<br/>Andreas Olenberg<br/>Aykut Yalcin",
                        translateMessage: false
                    },
                    {
                        headline: "designAndDevelopment",
                        message: "Aykut Yalcin",
                        translateMessage: false
                    },
                    {
                        headline: "contact",
                        message: "0521 94560626<br/>info@diefilmpiraten.de",
                        translateMessage: false
                    },
                    {
                        headline: "registryCourt",
                        message: "Amtsgericht Bielefeld",
                        translateMessage: false
                    },
                    {
                        headline: "salesTax",
                        message: "DE318837773<br/>Finanzamt Bielefeld",
                        translateMessage: false
                    },
                    {
                        headline: "liabilityContent",
                        message: "liabilityContentMessage",
                        translateMessage: true
                    },
                    {
                        headline: "liabilityLinks",
                        message: "liabilityLinksMessage",
                        translateMessage: true
                    },
                    {
                        headline: "copyright",
                        message: "copyrightMessage",
                        translateMessage: true
                    },
                    {
                        headline: "dataPrivacy",
                        message: "dataPrivacyMessage",
                        translateMessage: true
                    },
                    {
                        headline: "cookies",
                        message: "cookiesMessage",
                        translateMessage: true
                    },
                    {
                        headline: "serverLogFiles",
                        message: "serverLogFilesMessage",
                        translateMessage: true
                    },
                    {
                        headline: "googleAnalytics",
                        message: "googleAnalyticsMessage",
                        translateMessage: true
                    },
                    {
                        headline: "sslEncryption",
                        message: "sslEncryptionMessage",
                        translateMessage: true
                    },
                    {
                        headline: "legalCaveatCommercialMails",
                        message: "legalCaveatCommercialMailsMessage",
                        translateMessage: true
                    },
                ],
            },


            cancelCancerConfig: {
                name: "CancelCancer",
                url: "cancelcancer.de",
                menu: [
                    'news',
                    'association',
                    'goals',
                    'stories',
                    'ambassadors',
                    'contact',
                    'membership.message',
                    'donate',
                ],
                seoKeywords: "cancel, cancer, cancelcancer, krebs, krebsforschung, forschung, ev, verein",
                seoTitle: "CancelCancer.de",
                seoImage: "socialmediacc.png",
                clarityKey: "krk5tmid7f",

                buttons: [],
                showreel: "",
                awards: [],

                email: "info@cancelcancer.de",
                telDE: "0157 58910751",
                telEN: "+49 157 58910751",
                locations: [
                    {
                        logo: "bremerhaven.svg",
                        name: "Bremerhaven",
                        contact: "Cancel Cancer e.V.",
                        street: "Entenmoorweg 45",
                        zip: "27578",
                        city: "Bremerhaven",
                    },
                ],

                socials: [
                    {name: 'instagram', link: 'https://www.instagram.com/cancelcancer_cc/'},
                    {name: 'linkedin', link: 'https://www.linkedin.com/company/cancelcancer-foundation'},
                ],

                imagefilmSteps: [],

                aboutUsSteps: [],

                commercialSteps: [],

                team: [],

                features: [],

                subteam: [],

                sitenotice: [
                    {
                        headline: "statementLaw",
                        message: "Cancel Cancer e.V.<br/>Entenmoorweg 45<br/>27578 Bremerhaven<br/>Deutschland",
                        translateMessage: false
                    },
                    {
                        headline: "contact",
                        message: "0157 58910751<br/>info@cancelcancer.de",
                        translateMessage: false
                    },
                    {
                        headline: "Sitz des Vereins",
                        message: "Bremerhaven",
                        disableTranslateHeadline: true,
                        translateMessage: false
                    },
                    {
                        headline: "Registergericht",
                        message: "AG Bremen VR (neu)",
                        disableTranslateHeadline: true,
                        translateMessage: false
                    },
                    {
                        headline: "Vorstand",
                        message: "Timo Wentzel · Manuel Mohnke · Daniel Littau · Björn Leisten",
                        disableTranslateHeadline: true,
                        translateMessage: false
                    },
                    /*
                    {
                        headline: "ceo",
                        message: "Andreas Olenberg<br/>Manuel Mohnke",
                        translateMessage: false
                    },
                    {
                        headline: "content",
                        message: "Daniel Littau<br/>Andreas Olenberg<br/>Aykut Yalcin",
                        translateMessage: false
                    },

                     */
                    {
                        headline: "designAndDevelopment",
                        message: "Aykut Yalcin",
                        translateMessage: false
                    },

                    /*
                    {
                        headline: "registryCourt",
                        message: "Amtsgericht Bielefeld",
                        translateMessage: false
                    },
                    {
                        headline: "salesTax",
                        message: "DE318837773<br/>Finanzamt Bielefeld",
                        translateMessage: false
                    },

                     */
                    {
                        headline: "liabilityContent",
                        message: "liabilityContentMessage",
                        translateMessage: true
                    },
                    {
                        headline: "liabilityLinks",
                        message: "liabilityLinksMessage",
                        translateMessage: true
                    },
                    {
                        headline: "copyright",
                        message: "copyrightMessage",
                        translateMessage: true
                    },
                    {
                        headline: "dataPrivacy",
                        message: "dataPrivacyMessage",
                        translateMessage: true
                    },
                    {
                        headline: "cookies",
                        message: "cookiesMessage",
                        translateMessage: true
                    },
                    {
                        headline: "serverLogFiles",
                        message: "serverLogFilesMessage",
                        translateMessage: true
                    },
                    {
                        headline: "googleAnalytics",
                        message: "googleAnalyticsMessage",
                        translateMessage: true
                    },
                    {
                        headline: "sslEncryption",
                        message: "sslEncryptionMessage",
                        translateMessage: true
                    },
                    {
                        headline: "legalCaveatCommercialMails",
                        message: "legalCaveatCommercialMailsMessage",
                        translateMessage: true
                    },
                ],
            },


        };
    },
    computed: {
        getBaseUrl() {
            return this.$store.state.baseUrl;
        },
        getMode() {
            return this.$store.state.mode;
        },
        isDev() {
            return this.$store.state.isDev;
        },
        getConfig() {
            const mode = this.$store.state.mode;

            if (mode === "camcore") {
                return this.camcoreConfig;
            }

            if (mode === "filmpiraten") {
                return this.filmpiratenConfig;
            }

            if (mode === "cancelcancer") {
                return this.cancelCancerConfig;
            }


            return this.camcoreConfig;
        },
    },
    methods: {
        stopVideos() {
            document.querySelectorAll('video').forEach(function (vid) {
                if (vid && !vid.paused) {
                    vid.pause()
                }
            });
        },
        copyToClipboard(text) {
            navigator.clipboard.writeText(text);
        },
        routeTo(to) {
            this.$router.push("/" + i18n.locale + '/' + to);
        },
        capitalize(s) {
            if (typeof s !== 'string') return s;
            return s.charAt(0).toUpperCase() + s.slice(1)
        },
        stopVideo(id) {
            let video = document.getElementById(id);

            if (video && !video.paused) {
                video.pause();
            }
        },
        startVideo(id) {
            let video = document.getElementById(id);

            if (video && video.paused) {
                video.play();
            }
        },
        checkScrollTag(targetElementId) {
            let hashtag = location.hash;
            if (hashtag) {
                setTimeout(function () {
                    let elementId = targetElementId;
                    UIkit.scroll("#" + elementId).scrollTo("#" + elementId);
                }, 50);
            }
        },
        convertDate(date) {
            let dateSplit = date.split("-");
            let dateTimeSplit = dateSplit[2].split(" ");
            date = new Date(dateSplit[1] + "/" + dateTimeSplit[0] + "/" + dateSplit[0] + " " + dateTimeSplit[1]);

            let dateLang = 'en-US';
            if (this.$i18n.locale === 'de') {
                dateLang = 'de-DE';
            }

            return date.toLocaleDateString(dateLang, {
                weekday: 'long',
                year: 'numeric',
                month: '2-digit',
                day: '2-digit',
            }).replace(/[^ -~]/g, '');
        },
        convertTimestamp(timestamp) {
            let date = new Date(timestamp * 1000);

            let dateLang = 'en-US';
            if (this.$i18n.locale === 'de') {
                dateLang = 'de-DE';
            }

            return date.toLocaleDateString(dateLang, {
                weekday: 'long',
                year: 'numeric',
                month: '2-digit',
                day: '2-digit',
            }).replace(/[^ -~]/g, '');
        },
    }
})

export default {
    name: 'app',
    components: {Button, CookieControl, LinkObject, Footer},
    mounted() {
        document.documentElement.style.setProperty("--main-color", "#FAFAFA");
    },
    created() {
        if (
            location.hostname === 'www.vegankilling.de' ||
            location.hostname === 'vegankilling.de'
        ) {
            this.routeTo('/' + this.$t('inDevelopment').toLowerCase())
        }

        axios.defaults.timeout = this.apiTimeout;

        let origin = window.location.origin;
        if (origin.includes("localhost")) {
            let mode = "cancelcancer";

            this.$store.state.baseUrl = "https://" + (mode ==='filmpiraten' ? 'die':'') +mode + ".de";
            this.$store.state.mode = mode;
            this.$store.state.isDev = true;

        } else {
            this.$store.state.baseUrl = origin;

            /* eslint-disable no-console */
            console.log = function () {
            };
            /* eslint-enable no-console */
        }

        if (origin.includes("dev.")) {
            this.$store.state.isDev = true;
        }

        if (origin.includes("camcore")) {
            this.$store.state.mode = "camcore";
        }

        if (origin.includes("filmpiraten")) {
            this.$store.state.mode = "filmpiraten";
        }

        if (origin.includes("buddyinthebox")) {
            this.$store.state.mode = "buddyinthebox";
        }

        if (origin.includes("cancelcancer")) {
            this.$store.state.mode = "cancelcancer";
        }

        /*
        if (this.getMode === 'filmpiraten') {
            useFavicon('faviconfp.ico', {
                baseUrl: '/',
                rel: 'icon',
            });
        }
         */

        let language = this.$cookie.get('language');
        if (language) {
            this.$store.state.language = language;
            this.switchLanguage(language);
        }
    },
    methods: {
        redirect(target) {
            let link = document.createElement('a')
            link.href = target
            link.click()
        },
        switchLanguage(language) {

            if (this.$i18n.locale === language) {
                if (this.$store.state.cookieconsent === 'accepted') {
                    this.$cookie.set('language', language, {expires: this.cookieMaxExpiry});
                }

                return;
            }


            let currentRoute = this.$router.currentRoute.path;
            let languages = this.languages;

            if (!languages.includes(language)) {
                language = "en";
            }

            for (let i = 0, j = languages.length; i < j; i++) {
                if (languages[i] !== language) {
                    currentRoute = currentRoute.replace(languages[i], language);
                }
            }

            this.$i18n.locale = language;

            if (this.$store.state.cookieconsent === 'accepted') {
                this.$cookie.set('language', language, {expires: this.cookieMaxExpiry});
            }

            if (currentRoute !== this.$router.currentRoute.path) {
                this.$router.push(currentRoute);
            }
        },
    },
}

</script>


<style lang="scss">

.vc-container {
    border-radius: 0 !important;
    background-color: transparent !important;
}

.vc-bg-white {
    background-color: $main-color !important;
    color: #fff !important;
}

.vc-text-gray-900 {
    color: #fff !important;
}

.vc-rounded-lg {
    border-radius: 0 !important;
}

.strong-message {
    color: #f2f2ff;
    opacity: 0.9;
    line-height: 1.14286;
    letter-spacing: .007em;
    font-size: 24px;
    font-weight: 400;
}

.medium-message {
    color: #f2f2ff;
    opacity: 0.9;
    line-height: 1.14286;
    letter-spacing: .007em;
    font-size: 21px;
    font-weight: 400;
}

.language-element {
    cursor: pointer;
}

@media (max-width: 786px) {
    .visible-desktop {
        display: none !important;
    }
}

@media screen and (min-width: 600px) {
    .mobile-break {
        display: none;
    }
}

a {
    text-decoration: none !important;

    color: #ddd !important;
    transition: color .25s linear;

    &.hover, &:hover, &.active, &:active {
        color: #fff !important;
    }

    &.router-link-exact-active, &.router-link-active {
        color: #fff !important;
    }
}

.uk-button-disabled {
    text-transform: initial !important;
    font-size: 16px !important;
    background-color: $disabled-color !important;
    color: #fff !important;
    border: 1px solid transparent;
    cursor: not-allowed !important;
}

.menu-item {
    font-size: 16px;
    padding-top: $mobile-padding !important;
    padding-bottom: $mobile-padding !important;
}

.reset-line-height {
    line-height: initial !important;
}

.uk-offcanvas-bar {
    background: $alternative-color !important;
    -webkit-box-shadow: 0 14px 25px #000;
    box-shadow: 0 14px 25px #000;
}

.visible-mobile {
    display: none !important;
}

@media (max-width: 786px) {
    .visible-mobile {
        display: block !important;
    }
}

@media (min-width: 1400px) {
    .uk-padding-remove-left-desktop {
        padding-left: 0 !important;
    }

    .uk-padding-remove-right-desktop {
        padding-right: 0 !important;
    }
}

.navbar-ignore {
    margin-top: calc(#{$navigation-height} * -1);
}

.navbar-replace {
    height: $navigation-height;
}

.video-struct {
    min-height: 100vh;

    &.first-video {
        margin-top: -81px !important;
    }
}

.video-struct {
    background-color: $main-color !important;
}

.block-struct-desktop {
    min-height: 1000px;
    max-height: 1200px;

    padding-left: 40px !important;
    padding-right: 40px !important;

    &.max {
        min-height: 1200px;
    }

    &.min {
        min-height: 800px;
    }
}

.block-struct-background {
    background: $alternative-color;
}


.block-struct-mobile {
    padding: 100px 40px 100px 40px;
}

.mobile-padding {
    padding: 40px 20px 40px 20px;

    &.equal {
        padding: 20px 20px 20px 20px;
    }

    &.lower {
        padding: 10px 20px 20px 20px;
    }
}

@media (min-width: 426px) {
    .mobile-padding {
        padding: 50px 30px 50px 30px;

        &.equal {
            padding: 30px 30px 30px 30px;
        }

        &.lower {
            padding: 20px 30px 30px 30px;
        }
    }
}

@media (min-width: 1200px) {
    .mobile-padding {
        padding: 60px 40px 60px 40px;

        &.equal {
            padding: 40px 40px 40px 40px;
        }

        &.lower {
            padding: 30px 40px 40px 40px;
        }
    }
}

@media (max-width: 416px) {

    .block-struct-mobile {
        padding-left: $mobile-padding;
        padding-right: $mobile-padding;
    }

    .mobile-padding {
        padding-left: $mobile-padding;
        padding-right: $mobile-padding;
    }
}

@media (max-width: 379px) {
    .block-struct-mobile {
        padding-left: $mobile-padding;
        padding-right: $mobile-padding;

    }
}

.country-flag {
    width: 24px !important;
    height: 24px !important;
    border-radius: 500px;
}

html, body {
    margin: 0;
    padding: 0;
    overflow-x: hidden;
    min-height: 100%;
    background-color: $main-color !important;
    color: $font-color !important;
}

.uk-modal-body {
    background-color: $alternative-color !important;
}

.uk-modal-header {
    background-color: $alternative-color !important;
    border-bottom: 1px solid #e5e5e5;
}

.uk-modal-footer {
    background-color: $alternative-color !important;

    border-top: 1px solid #e5e5e5;
}


.language-modal {
    width: 500px !important;
}

#app {
    margin: auto;

}

video {
    outline: none !important;
}

.site-width {
    max-width: $max-site-width;
    margin: auto;

    &.single {
        max-width: 920px;
    }

    &.divided {
        max-width: 1200px;
    }
}

.uk-card-alternative {
    background-color: $alternative-color;
}

@media (max-width: 1400px) {
    .site-width {
        padding-left: 40px;
        padding-right: 40px;

        &.no-padding {
            padding-left: 0;
            padding-right: 0;
        }
    }
}

@media (max-width: 416px) {
    .site-width {
        padding-left: $mobile-padding;
        padding-right: $mobile-padding;

        &.no-padding {
            padding-left: 0;
            padding-right: 0;
        }
    }
}

.uk-container {
    max-width: $max-site-width !important;
}

.navbar-struct {
    transition: background-color .25s linear, border .25s linear;
}

.padding-left-small {
    padding-left: 5px;
}

.navbar-elements {
    max-width: 1368px;
}

.navbar-sticky-struct {
    background-color: rgba(29, 29, 31, 0.72);
    backdrop-filter: saturate(180%) blur(20px);
    border-bottom: 1px solid rgba(255, 255, 255, 0.24) !important;
}

.uk-navbar-item, .uk-navbar-nav > li > a, .uk-navbar-toggle {
    min-height: $navigation-height !important;
}

.content-struct {
    padding: 118px 20px 0 20px;
}

.uk-button {
    text-transform: initial !important;
}

@media (min-width: 960px) {
    ::-webkit-scrollbar {
        width: 10px;
    }

    ::-webkit-scrollbar-track {
        background: #666;
    }

    ::-webkit-scrollbar-thumb {
        background: #ddd;
    }
}

.logo {
    color: #000;
    width: 140px;
    max-height: calc($navigation-height - 20px);
}

.button-menu {
    font-size: 16px !important;
    font-weight: 600;
}

.uk-navbar-nav > li > a {
    text-transform: initial !important;
    font-size: 16px !important;
    color: #ddd !important;

    font-weight: 600;
    transition: color .25s linear !important;

    &.hover, &:hover {
        color: #fff !important;
    }

    &.active, &:active {
        color: #fff !important;
    }

    &.language {
        font-size: 14px !important;
        margin-top: -2px;
    }

    &.router-link-exact-active, &.router-link-active {
        color: #fff !important;
    }

}

.uk-dropdown-nav .uk-nav-header {
    color: #ddd !important;
}

.uk-dropdown {
    background: $main-color !important;
}

.uk-dropdown-nav > li > a {
    color: #ccc !important;
    transition: color .25s linear !important;

    &.hover, &:hover {
        color: #fff !important;
    }

    &.uk-active {
        color: #fff !important;
    }
}

.button-success {
    text-transform: initial !important;
    font-size: 16px !important;
    color: #eee !important;
    border: 1px solid transparent;
    background: $success-color !important;
    transition: background .25s linear !important;


    &.hover, &:hover, &.active, &:active, &.focus, &:focus {
        color: #fff !important;
        background: $success-color-hover !important;
    }

    &.button-small {
        font-size: 14px !important;
    }

    &.secondary {
        background: $fp-color !important;

        &.hover,
        &:hover,
        &.active,
        &:active,
        &.focus,
        &:focus {
            background: $fp-color-hover !important;

        }
    }
}

.uk-button {
    font-size: 16px !important;
}

.large-button {
    min-width: 200px;
}

.large-input {
    //width: 200px !important;
    height: 50px !important;
}


.headline-button-distance {
    margin-top: 30px;
}

.uk-link-white {
    color: #ccc !important;
    transition: color .25s linear;

    &.hover, &:hover {
        color: #fff !important;
    }
}

@supports (-webkit-overflow-scrolling: touch) {
    .video-struct {
        min-height: 90vh;
    }
}

.menu-headline-distance {
    margin-top: 80px;
}

.headline-message-distance {
    margin-top: 60px;
}

.headline-message-distance-small {
    margin-top: 30px;
}

.headline-message-distance-medium {
    margin-top: 50px;
}

.highlighted-text {
    color: #f2f2ff;
    opacity: 0.7;
}

.adjusted-viewport {
    height: calc(100vh - 100px - 70px)
}

.cursor-pointer {
    cursor: pointer;
}

.white {
    color: #fff !important;
}

.uk-height-small-medium {
    height: 220px;
}

.uk-padding-top {
    padding-top: 40px;
}

.uk-dark-card-hover:hover {
    animation-name: test;
}

.uk-dark-card-hovered {
    box-shadow: 0 14px 25px rgba(0, 0, 0, 0.16);
    transition: box-shadow .25s linear !important;

    &:hover, &.hover {
        box-shadow: 0 14px 25px rgba(0, 0, 0, 1);
    }
}

.uk-dark-card-hovered-blank {
    box-shadow: 0 14px 25px rgba(0, 0, 0, 0.0);
    transition: box-shadow .25s linear !important;

    &:hover, &.hover {
        box-shadow: 0 14px 25px rgba(0, 0, 0, 1);
    }
}

.item-distance {
    margin-top: 100px;
}

@media (max-width: $max-site-width) {
    .item-distance {
        margin-top: 70px;
    }
}

.underline {
    text-decoration: underline !important;
}

.dark-input {
    background-color: $main-color !important;
    border: 1px solid #666 !important;
    color: #ddd !important;

    &:focus {
        border: 1px solid #999 !important;
    }
}

.input-error {
    color: $danger-color-hover;
}

.section-distance {
    margin-top: 80px;
}

.section-distance-new {
    margin-top: 100px;
}

.headline-item-distance {
    margin-top: 40px;
}

.last-button-distance {
    margin-top: 100px;
}

.last-section-distance {
    margin-top: 100px;
}

.cursor-notallowed {
    cursor: not-allowed;
}

.small-padding {
    padding: 5px;
}

.input-headline {
    margin-bottom: 10px;
    font-size: 15px;
    font-weight: 400;
}

textarea {
    resize: vertical;
}

.preferred-item {
    margin-left: 10px;
}

.uk-checkbox:disabled, .uk-radio:disabled {
    background-color: $disabled-color !important;
}

.uk-width-mini {
    width: 50px !important;
}

.respond-info {
    margin-top: 8px;
}

.uk-padding-very-small {
    padding: 10px;
}

.input-headline {
    margin-bottom: 5px;
}

.uk-button-default {
    transition: border-color .25s linear, color .25s linear;

    color: #ddd !important;
    border: 1px solid #b2b2b2 !important;
}

.uk-button-default:hover,
.uk-button-default:focus {
    color: #fff !important;
    border-color: #e5e5e5 !important;
}

.uk-button-default:active,
.uk-button-default.uk-active {
    color: #fff !important;
    border-color: #e5e5e5 !important;
}

.vc-rounded {
    border-radius: 0 !important;
    max-width: calc(100% - 26px) !important;
    background-color: rgba(255, 255, 255, 0.1) !important;
    color: #ddd !important;
    border-color: rgba(255, 255, 255, 0.2) !important;
    transition: border-color .25s linear;

    &.hover, &:hover, &.active, &:active, &.focus, &:focus {
        border-color: rgba(255, 255, 255, 0.7) !important;
    }
}

.uk-button {
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.08);

    &.primary {
        background-color: $info-color !important;
    }
}

.uk-button {
    text-transform: capitalize !important;

    &:first-letter {
        // text-transform: capitalize!important;
    }

}

.language-dist {
    margin-left: 3px !important;
}

.uk-subnav-pill > li > * {
    text-transform: initial !important;
    border: 1px solid #aaa !important;
    font-size: 15px !important;
    font-weight: 500;
    border-radius: $border-radius;
}

.uk-card {
    border-radius: $border-radius;
}

.uk-subnav-pill > * > a:hover {
    color: #fff !important;
    background-color: $info-color !important;

    &.secondary {
        background-color: $fp-color !important;
    }
}

.uk-subnav-pill > .uk-active > a {
    color: #fff !important;
    background-color: $info-color-hover !important;
    border: 1px solid #e5e5e5 !important;

    &.secondary {
        background-color: $fp-color-hover !important;
    }
}

.new-section-struct {
    padding: 100px 40px 100px 40px !important;
}

@media (max-width: 416px) {
    .new-section-struct {
        padding: 100px 20px 100px 20px !important;
    }
}

.lang-struct {
    margin-top: 5px;
}

.video-item {
    border-top-left-radius: $border-radius;
    border-top-right-radius: $border-radius;
}

.uk-padding-xsmall {
    padding: 18px !important;
}

.uk-modal-dialog {
    border-radius: $border-radius;
}

.donate-text {
    font-size: 18px;
}

.donate-item {
    font-size: 20px;
    font-weight: 500;
}

</style>
