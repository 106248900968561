<template>
    <div class="main">

        <Seo :title="$t('mainTitle')" canonical=""/>

        <!-- MAIN -->
        <div class="uk-grid-collapse uk-grid-match" uk-grid>
            <div class="uk-width-1-1 video-struct first-video">
                <div class="uk-cover-container uk-height-1-1 uk-animation-fade">
                    <video id="video-main" class="video-main"
                           muted
                           oncontextmenu="return false;" playsinline uk-cover="autoplay: inview">
                        <source :src="require('../assets/'+getMode+'/videos/main.mp4')" type="video/mp4">
                    </video>
                    <VideoLoop id="video-main"/>


                    <div v-if="getMode === 'cancelcancer'"
                         class="uk-position-center uk-text-center">


                        <SiteHeadline is-main message="Für eine Kindheit ohne Krebs"
                                      has-button>
                            <a href="#campaign">
                                <Button text="Jetzt Kampagnenvideo ansehen" is-large is-min-width mode="default"
                                        uk-toggle="target: #modal-campaign"/>
                            </a>


                            <div class="uk-margin-medium-top"/>
                            <router-link :to="'/'+$i18n.locale+'/mitgliedwerden'">
                                <Button text="Jetzt Mitglied werden" is-large is-min-width mode="success"/>
                            </router-link>
                        </SiteHeadline>


                        <VideoModal modal-id="modal-campaign"
                                    video-src="https://www.youtube.com/watch?v=b2U7GiABi_A"/>
                    </div>

                    <div v-else class="uk-position-center uk-text-center"
                         :uk-scrollspy="getMode === 'filmpiraten' ? 'cls: uk-animation-fade; delay: 2000;':''">
                        <SiteHeadline is-main :headline="$t('mainHeadline')"
                                      :message="$t('mainMessage')"
                                      has-button>
                            <PromotionButtons :buttons="getConfig.buttons"/>
                        </SiteHeadline>
                    </div>


                    <div class="uk-position-bottom uk-text-center uk-margin-bottom next-item"
                         uk-scrollspy="cls: uk-animation-slide-top-small; delay: 1000;">
                        <a class="uk-link-muted uk-link-white" href="#features" uk-scroll="offset: 15">
                            <span uk-icon="icon: chevron-down; ratio: 2"></span>
                        </a>
                    </div>


                </div>
            </div>
        </div>
        <!-- /MAIN -->

        <!-- /FEATURES -->
        <span id="features"/>
        <div  v-if="getMode ==='camcore'" class="new-section-struct site-width ">
            <SiteHeadline :headline="$t('companyPhilosophy')" :message="$t('whyChooseX', {x: getConfig.name})"
                          class="uk-margin-bottom"/>

            <div class="headline-message-distance"/>

            <HighlightVideoText is-equal :service-items="serviceItems" toggle-sides>


                <template v-slot:after-text>
                    <div class=" uk-text-center uk-width-1-1">
                        <div class="uk-margin-bottom">
                            {{ $t('freeOffer') }}
                        </div>
                        <router-link :to="'/'+$i18n.locale+'/'+$t('references').toLowerCase()+'#behindTheScenes'">
                            <Button :text="$t('behindTheScenes')" is-large is-min-width mode="default"/>
                        </router-link>
                    </div>
                </template>

            </HighlightVideoText>




        </div>

        <div v-if="getMode ==='filmpiraten'" class="new-section-struct site-width single">
            <SiteHeadline :message="$t('inHouseHeadline')" has-button>

                <FeatureItem class="uk-text-left"
                             message="
                          Wir kreieren und produzieren Werbespots und Imagefilme, einfach jedwede Form von
                          Bewegtbild, die denkbar ist. Und das in bester Kinoqualität. Dank unserer Leidenschaft und vor allem
                          jahrelangen Erfahrung wissen wir, worauf es bei einem guten Film ankommt."/>

                <div class="block-button-distance uk-text-center uk-width-1-1">
                    <router-link :to="'/'+$i18n.locale+'/'+$t('contact').toLowerCase()">
                        <Button :text="$t('contact')" is-large is-min-width mode="success"/>
                    </router-link>
                    <div class="uk-margin-small-top">
                        {{ $t('freeOffer') }}
                    </div>
                </div>

            </SiteHeadline>
        </div>

        <div v-if="getMode ==='cancelcancer'">
            <FactsList
                message="10 Fakten über Krebs"
                headline="Warnung und Hoffnung zugleich"
                switch-headline
                :facts="[
                    'Fünf Kinder erhalten in Deutschland täglich die Diagnose Krebs.',
                    'Die Überlebensrate liegt bei 80%. Doch der Preis ist hoch.',
                    'Viele haben im Erwachsenenalter mit Spätfolgen zu kämpfen.',
                    'Krebs ist die zweithäufigste Todesursache bei Kindern und Jugendlichen in Deutschland.',
                    'Kinder unter fünf Jahren erkranken häufiger als in jedem anderen Alter.',
                    'Lediglich zwei neue Medikamente wurden in der EU von 2000 bis 2016 speziell für die Behandlung von Krebs bei Kindern zugelassen',
                    'Die Heilungschancen sind in den letzten 30 Jahren deutlich gestiegen.',
                    '300.000 Kinder erkranken jährlich weltweit.',
                    'In Deutschland erkranken jährlich ca. 2.000 Kinder und Jugendliche an Krebs.',
                    'Aufgrund der geringen Fallzahlen ist die Kinderkrebsforschung von privaten Spenden abhängig.'
                ]"
                class="new-section-struct"/>

            <PartnerList
                message="Unsere Partner"
                switch-headline
                :partners="[
                    'UFA.png',
                    'Stiftung Stern.svg',
                    'Flyeralarm.svg',
                    'Camcore.svg',
                    'Die Filmpiraten.svg',
                    'Kensington.svg',
                    'Zilver Berlin.svg',
                    'Kanzlei Reinold.svg',
                    'Stroschein.svg',
                    'Devletli.svg',
                    'The Eagles.svg',
                ]"
                headline="Gemeinsam für eine Kindheit ohne Krebs"
                class="new-section-struct block-struct-background"/>

            <div class="uk-margin-medium-top"/>
            <LastHeadlineButton headline="Du möchtest Partner von unserem Verein werden?" is-large>
                <PromotionButtons :buttons="['contact']"/>
            </LastHeadlineButton>
            <div class="uk-margin-medium-top"/>
        </div>

        <!-- /FEATURES -->

        <!-- SHOWREEL -->
        <span id="showreel" v-if="getConfig.showreel">
            <div class="uk-grid-collapse video-struct uk-grid-match" uk-grid>
                <div class="uk-width-1-1 uk-height-viewport">
                    <div class="uk-cover-container uk-height-1-1 uk-animation-fade">
                        <video id="video-showreel" class="video-showreel"
                               muted
                               oncontextmenu="return false;" playsinline uk-cover="autoplay: inview">
                            <source :src="require('../assets/'+getMode+'/videos/showreel.mp4')" type="video/mp4">
                        </video>
                        <VideoLoop id="video-showreel"/>

                        <div class="uk-position-center uk-text-center">
                            <SiteHeadline
                                :headline="$t('showreel')"
                                :message="$t('showreelMessage')"
                                has-button
                                uk-scrollspy="cls: uk-animation-slide-bottom-small; delay: 200">
                                <a href="#showreel">
                                <Button :text="$t('watch')" is-large is-min-width mode="default"
                                        uk-toggle="target: #modal-showreel"/>
                                    </a>
                            </SiteHeadline>
                        </div>
                    </div>
                </div>
            </div>
        </span>

        <VideoModal v-if="getConfig.showreel" modal-id="modal-showreel"
                    :video-src="getConfig.showreel"/>
        <!-- /SHOWREEL -->


        <!-- REFERENCES -->
        <VideoList v-if="videos && videos.length>0" :headline="$t('references')" :message="$t('videoListMessage')"
                   :videos="videos"/>
        <!-- /REFERENCES -->

        <!-- AWARD -->
        <AwardList v-if="getConfig.awards && getConfig.awards.length>0" :awards="getConfig.awards"
                   :headline="$t('awards.message')" :message="$t('awards')"
                   class="new-section-struct block-struct-background"
                   switch-headline/>
        <!-- /AWARD -->

        <!-- CUSTOMERS -->
        <CustomersList v-if="customers && customers.length>0" :customers="customers" :headline="$t('customersMessage')"
                       switch-headline
                       :message="$t('customersHeadline')" class="new-section-struct"/>
        <!-- /CUSTOMERS -->

        <NumberReferences v-if="false" class="site-width new-section-struct"/>

        <LastHeadlineButton v-if="getConfig.buttons && getConfig.buttons.length>0"
                            :headline="$t('weHaveTheRightThingForYou')" is-large>
            <PromotionButtons :buttons="getConfig.buttons"/>
        </LastHeadlineButton>


    </div>
</template>


<script>

import SiteHeadline from "../components/SiteHeadline";
import VideoModal from "../components/VideoModal";
import Seo from "../components/Seo";
import LastHeadlineButton from "../components/LastHeadlineButton";
import UIkit from "uikit";
import CustomersList from "../components/CustomersList";
import VideoList from "@/components/VideoList";
import Button from "@/components/Button";
import axios from "axios";
import NumberReferences from "@/components/NumberReferences";
import PromotionButtons from "@/components/PromotionButtons";
import VideoLoop from "@/components/VideoLoop";
import AwardList from "@/components/AwardList";
import FeatureItem from "@/components/FeatureItem.vue";
import FactsList from "@/components/FactsList.vue";
import PartnerList from "@/components/PartnerList.vue";
import HighlightVideoText from "@/components/HighlightVideoText.vue";

export default {
    name: 'Main',
    components: {
        HighlightVideoText,
        PartnerList,
        FactsList,
        FeatureItem,
        AwardList,
        VideoLoop,
        PromotionButtons,
        NumberReferences,
        Button,
        VideoList,
        CustomersList,
        LastHeadlineButton,
        Seo,
        VideoModal,
        SiteHeadline
    },
    data() {
        return {
            publicPath: process.env.BASE_URL,

            videos: [],
            customers: [],

            serviceItems: [
                {
                    headline: "",
                    title: "",
                    message: "main.text",
                    video: "bts.mp4"
                },
            ],
        };
    },
    computed: {
        trimmedFeatures() {
            let features = this.features;
            let filtered = features.filter(function (el) {
                if (el.title) return el;
            });

            return filtered;
        },
    },
    mounted() {
        let hashtag = location.hash;
        if (hashtag && ['#showreel', '#campaign'].includes(hashtag)) {

            if (hashtag !== '#campaign') {
                setTimeout(function () {
                    UIkit.scroll(hashtag).scrollTo(hashtag);
                }, 50);
            }

            const modalId = "modal-" + hashtag.substring(1);
            let self = this;
            UIkit.modal("#" + modalId).show();

            const modalElement = document.getElementById(modalId);
            modalElement.addEventListener("beforeshow", function () {
                self.interval = setInterval(function () {
                    self.stopVideos();
                }, 100);
            });

            modalElement.addEventListener("beforehide", function () {
                clearInterval(self.interval);
            });
        }
    },
    created() {
        if (this.getMode === 'cancelcancer') {
            return;
        }

        this.getFavoriteReferences();
        this.getCustomers();
    },
    methods: {
        getCustomers() {
            let http = axios.create({
                timeout: this.$store.state.apiTimeout,
            });

            http.get(this.getBaseUrl + '/api/customers').then(response => {
                this.customers = response.data;

            }).catch(() => {
            });
        },
        getFavoriteReferences() {
            let http = axios.create();

            http.get(this.getBaseUrl + '/api/references/favorites').then(response => {
                this.videos = response.data;

            }).catch(() => {

            });
        },
    }
}
</script>


<style lang="scss" scoped>

.video-main {
    opacity: 0.55;
}

.video-showreel {
    opacity: 0.6;
}

.section-headline-distance {
    margin-top: 80px;
}

.block-button-distance {
    margin-top: 50px;

    &.additional-distance {
        margin-top: 100px;
    }
}

.block-headline-distance {
    margin-bottom: 80px;
}

.text-button-distance {
    margin-top: 100px;
}

.title-distance {
    margin-top: 120px;
}

.view-distance {
    height: 12px;
}

.uk-card-dark {
    background: $alternative-color;
    color: #fff;
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.08);
}

.uk-width-very-small {
    width: 70px;
}

#features {
    padding-top: -70px;
}


.city-logo {
    max-height: 130px;
}

.location-address {
    color: #f2f2ff;
    opacity: 0.9;
    line-height: 1.4;
    letter-spacing: .007em;
    font-size: 18px;

    &.name {
        font-size: 22px;
        font-weight: 500;
    }
}

.available-info {
    margin-top: 10px;
    font-size: 15px;
}

.location-name {
    line-height: 1.0625;
    font-weight: 500;
    letter-spacing: -.009em;
    margin-top: 15px !important;
    margin-bottom: 20px !important;
    font-size: 22px;
}

.address-button {
    margin-top: 10px;
    font-size: 16px !important;
    font-weight: 500 !important;
    min-width: 190px;
}

</style>
