var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"team"},[_c('SiteHeadline',{attrs:{"headline":_vm.headline,"message":_vm.message,"switch-headline":_vm.switchHeadline}}),_c('div',{staticClass:"headline-item-distance"}),_c('div',{class:'uk-grid uk-flex-center uk-grid-match ' + _vm.grid,attrs:{"uk-grid":""}},_vm._l((_vm.team),function(item){return _c('div',{key:item.name},[_c('div',{staticClass:"uk-card uk-card-alternative uk-height-1-1 uk-dark-card-hovered"},[_c('div',{staticClass:"video-struct-item uk-width-1-1"},[_c('div',{staticClass:"uk-cover-container uk-animation-fade"},[(item.video)?_c('video',{staticClass:"uk-width-1-1 video-item",attrs:{"id":'person-' + item.name,"muted":"","oncontextmenu":"return false;","playsinline":"","uk-video":"autoplay: inview"},domProps:{"muted":true}},[_c('source',{attrs:{"src":require('../assets/videos/' +
                                        item.video),"type":"video/mp4"}})]):_vm._e(),(item.video)?_c('VideoLoop',{attrs:{"id":'person-' + item.name}}):_vm._e(),(item.avatar)?_c('img',{staticClass:"image",attrs:{"src":require('../assets/' +
                                    _vm.getMode +
                                    '/ambassadors/' +
                                    item.avatar)}}):_vm._e()],1)]),_c('div',{staticClass:"uk-text-center uk-card-body uk-padding-xsmall"},[_c('div',{staticClass:"message",domProps:{"innerHTML":_vm._s(item.name)}}),_c('div',{staticClass:"headline",domProps:{"innerHTML":_vm._s(
                            item['title' + _vm.$i18n.locale.toUpperCase()]
                        )}})])])])}),0)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }